import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/scss/style.scss";
import Home from "./pages/home/Home";
import Create from "./pages/create/Create";
import toast, { Toaster } from 'react-hot-toast';


import { clusterApiUrl } from "@solana/web3.js";
import { PhantomWalletAdapter, SolflareWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  useWalletModal,
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import Update from "./pages/update/Update";
require("@solana/wallet-adapter-react-ui/styles.css");
function App() {

  const solNetwork = process.env.REACT_APP_RPC;
  const endpoint = useMemo(() => solNetwork, [solNetwork]);
  const wallets = useMemo(() => [new PhantomWalletAdapter()], [solNetwork]);
  return (

    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/create/:name" element={<Create />} />
              <Route path="/edit/:name" element={<Update />} />
            </Routes>
          </div>
          <Toaster position="bottom-center" />

        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
