import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from "reactstrap";
import { trimAddress } from "../utils/global";

function Navbarr({ onConnect, connected, publicKey, onOpenProfile }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar light fixed="top" expand="md" container>
        <NavbarBrand href="/">
          <span className="f1">WEBM</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink
                className="f1"
                onClick={() =>
                  window.open("https://t.me/bumpisfunbot", "_blank")
                }
              >
                Telegram
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() =>
                  window.open("https://t.me/bumpisfunsupport", "_blank")
                }
                className="f1"
              >
                Support
              </NavLink>
            </NavItem>
            <NavItem>
              <Button
                className="connect-wallet"
                color="info"
                onClick={() => onConnect()}
                x-if={!connected}
              >
                <img src={require("../assets/images/phantom-fill.png")} />
                <span className="f2">Connect</span>
              </Button>
              <Button
                className="connect-wallet"
                color="info"
                onClick={() => onOpenProfile()}
                x-else-if={connected}
              >
                <img src={require("../assets/images/phantom-fill.png")} />
                <span className="f2">{trimAddress(publicKey.toString())}</span>
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Navbarr;
