import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { trimAddress } from "../utils/global";

function ProfileModal({ open, onClose, publicKey, balance, onDisconnect }) {
  const toggle = () => {
    if (open) {
      onClose();
    }
  };

  return (
    <Modal isOpen={open} toggle={toggle} centered className="profile-modal">
      <ModalBody className="profile-modal-body d-flex flex-column align-items-center">
        <img src={require("../assets/images/phantom-fill.png")} />
        <h5 className="f2 mt-3" x-if={publicKey}>
          {trimAddress(publicKey.toString())}
        </h5>
        <h3 className="f1" x-if={balance !== null}>
          {balance} SOL
        </h3>

        <div className="buttons">
          <Button
            color="danger"
            className="me-1"
            outline
            onClick={onDisconnect}
          >
            Disconnect
          </Button>
          <Button onClick={toggle} className="ms-1" color="light" outline>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ProfileModal;
