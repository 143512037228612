import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../components/Navbar";

import {
  clusterApiUrl,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  useWalletModal,
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import "../create/create.scss";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  Label,
  InputGroupText,
  Form,
} from "reactstrap";
import ProfileModal from "../../components/ProfileModal.jsx";
import bs58 from "bs58";
import { generateNonce } from "../../utils/global.js";
import API from "../../utils/api.js";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";

require("@solana/wallet-adapter-react-ui/styles.css");

const themes = [
  {
    name: "Classic",
    code: "classicsimple",
  },
  {
    name: "Classic Advance",
    code: "classicadvance",
  },
];
export default function Update() {
  const solNetwork = clusterApiUrl("devnet");
  const endpoint = useMemo(() => solNetwork, [solNetwork]);
  const wallets = useMemo(() => [new PhantomWalletAdapter()], [solNetwork]);
  const { connected, publicKey, signMessage, sendTransaction, disconnect } =
    useWallet();
  const { connection } = useConnection();
  const { setVisible } = useWalletModal();

  const [nonce, setNonce] = useState(null);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);

  const [component, setComponent] = useState(null);
  const [templateFiles, setTemplateFiles] = useState({
    backgroundImage: null,
    banner: null,
    logo: null,
  });
  const [deployStatus, setDeployStatus] = useState(null);
  const [deployLoading, setDeployLoading] = useState(false);
  const [templateData, setTemplateData] = useState({
    theme: "",
    subdomain: "",
    name: "Your Project Name",
    ticker: "TICKER",
    color: "#FFFFFF",
    background: "#9844FF",
    ca: "",
    logo: "",
    description: "Fill your project description",
    about: "",
    backgroundImage: "",

    banner: "",

    socials: {
      dexscreener: null,
      raydium: null,
      jupiter: null,
      pumpfun: null,
      telegram: null,
      twitter: null,
      birdeye: null,
    },
  });
  const { name } = useParams();

  const [sidebarCollapse, setSidebarCollapse] = useState({
    theme: true,
    tokenInfo: false,
    projectInfo: false,
    socials: false,
    style: false,
  });
  const [profileModal, setProfileModal] = useState(false);
  const [solBalance, setSolBalance] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getLayout();
  }, []);

  useEffect(() => {
    if (templateData.theme) {
      updateTheme();
    }
  }, [templateData.theme]);

  useEffect(() => {
    if (connected) {
      if (!localStorage.getItem("jwt")) {
        loginSign();
      }
      getSolBalance();
    }
  }, [connected]);

  useEffect(() => {
    login();
  }, [signature]);

  useEffect(() => {
    if (deployStatus == "updating") {
      toast.dismiss();
      toast.loading("Updating...");
    } else if (deployStatus == "success") {
      toast.dismiss();
      toast.success("Website updated successfully");
    } else if (deployStatus == "error") {
      toast.dismiss();
      toast.error("Failed to update");
    }
  }, [deployStatus]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const getLayout = async () => {
    let _template = await API.get(`web/find/${name}`).catch(() => false);
    if (!_template) return;

    const imgKeys = ["logo", "banner", "backgroundImage"];
    for (const img of imgKeys) {
      if (_template.data.data[img]) {
        _template.data.data[img] =
          process.env.REACT_APP_BASE_URL + "/" + _template.data.data[img];
      }
    }
    setTemplateData(_template.data.data);
    setterTemplate("theme", _template.data.data.template);

    const templateFiles = {
      classicsimple: "ClassicSimple",
      classicadvance: "ClassicAdvance",
    };
    const importedComponent = await import(
      `../../Template/${_template.data.data.template}/${
        templateFiles[_template.data.data.template]
      }.jsx`
    );
    setComponent(importedComponent);
  };

  const updateTheme = async () => {
    const templateFiles = {
      classicsimple: "ClassicSimple",
      classicadvance: "ClassicAdvance",
    };
    const importedComponent = await import(
      `../../Template/${templateData.theme}/${
        templateFiles[templateData.theme]
      }.jsx`
    );
    setComponent(importedComponent);
  };

  const toggleSidebarCollapse = (name) => {
    setSidebarCollapse((p) => ({
      ...p,
      [name]: !p[name],
    }));
  };

  const setterTemplate = (key, value) => {
    setTemplateData((p) => ({
      ...p,
      [key]: value,
    }));
  };
  const setterSocials = (key, value) => {
    setTemplateData((p) => ({
      ...p,
      socials: {
        ...p.socials,
        [key]: value,
      },
    }));
  };

  const loginSign = () => {
    const nonce = generateNonce(12);
    setNonce(nonce);
    const timestamp = Date.now();
    setTimestamp(timestamp);
    const messageText = `Sign in with Solana to webm.fun app.\nNonce: ${nonce}\nTimestamp: ${timestamp}`;
    const messageEncoded = new TextEncoder().encode(messageText);
    signMessage(messageEncoded)
      .then((resp) => {
        setSignature(bs58.encode(resp));
        login();
      })
      .catch(() => {});
  };

  const login = () => {
    if (!signature) return;
    API.post("login", {
      nonce: nonce,
      timestamp: timestamp,
      signature: signature,
      address: publicKey ? publicKey.toString() : "",
    }).then((res) => {
      localStorage.setItem("jwt", res.data.token);
      setTimeout(() => {
        getLayout();
      }, 1500);
    });
  };

  const renderTemplate = () => {
    if (!component) return;
    const Component = component.default;
    return <Component data={templateData} />;
  };

  const handleFileUpload = (e, name) => {
    setTemplateFiles((p) => ({
      ...p,
      [name]: e.target.files[0],
    }));

    setterTemplate(name, URL.createObjectURL(e.target.files[0]));
  };

  const getSolBalance = async () => {
    if (!publicKey) return;
    const balanceRaw = await connection.getBalance(publicKey);
    const balance = parseFloat(balanceRaw / LAMPORTS_PER_SOL).toFixed(3);
    setSolBalance(balance);
  };

  const deployWebsite = async () => {
    //upload image
    if (deployLoading) return;
    setDeployLoading(true);
    setDeployStatus("updating");
    try {
      let backgroundImageUrl = "";
      if (templateFiles.backgroundImage) {
        const uploading = await API.upload(templateFiles.backgroundImage);
        if (uploading.result) {
          backgroundImageUrl = uploading.result;
        }
      }

      let logoUrl = "";
      if (templateFiles.logo) {
        const uploading = await API.upload(templateFiles.logo);
        if (uploading.result) {
          logoUrl = uploading.result;
        }
      }
      let bannerUrl = "";
      if (templateFiles.banner) {
        const uploading = await API.upload(templateFiles.banner);
        if (uploading.result) {
          bannerUrl = uploading.result;
        }
      }

      const payload = {
        ...templateData,
        template: templateData.theme,
      };
      if (backgroundImageUrl) payload.backgroundImage = backgroundImageUrl;
      if (logoUrl) payload.logo = logoUrl;
      if (bannerUrl) payload.banner = bannerUrl;

      const deploying = await API.patch("web", payload).catch(() => false);
      if (!deploying) {
        setDeployStatus("error");
        setDeployLoading(false);
        return;
      }
      setDeployStatus("success");
      setDeployLoading(false);
    } catch {
      setDeployLoading(false);
      setDeployStatus("error");
    }
  };
  return (
    <div className="create-container">
      <Navbar
        onConnect={() => setVisible(true)}
        connected={connected}
        publicKey={publicKey}
        onOpenProfile={() => setProfileModal(true)}
      />

      <ProfileModal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        publicKey={publicKey}
        balance={solBalance}
        onDisconnect={() => {
          disconnect();
          localStorage.removeItem("jwt");
          setProfileModal(false);
        }}
      />

      <div className="layout-container">
        <Form
          className="sidebar"
          onSubmit={(e) => {
            e.preventDefault();
            deployWebsite();
          }}
        >
          <div className="sidebar-inner">
            <Input
              type="text"
              value={`${templateData.subdomain}.webm.fun`}
              disabled
            ></Input>

            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("theme")}
            >
              Meme Theme
            </div>
            <Collapse isOpen={sidebarCollapse.theme}>
              <Card className="collapse-card">
                <Button
                  type="button"
                  className="w-100"
                  color={
                    theme.code === templateData.theme ? "primary" : "light"
                  }
                  x-for={(theme, i) in themes}
                  onClick={() => setterTemplate("theme", theme.code)}
                >
                  {theme.name}
                </Button>
              </Card>
            </Collapse>

            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("style")}
            >
              Style
            </div>
            <Collapse isOpen={sidebarCollapse.style}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="color" className="f2">
                    Color
                  </Label>
                  <Input
                    id="color"
                    name="color"
                    placeholder=""
                    type="color"
                    className="f2"
                    value={templateData.color}
                    onChange={(e) => setterTemplate("color", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="background" className="f2">
                    Background Color
                  </Label>
                  <Input
                    id="background"
                    name="background"
                    placeholder=""
                    type="color"
                    className="f2"
                    value={templateData.background}
                    onChange={(e) =>
                      setterTemplate("background", e.target.value)
                    }
                  />
                </FormGroup>
              </Card>
            </Collapse>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("tokenInfo")}
            >
              Token Info
            </div>
            <Collapse isOpen={sidebarCollapse.tokenInfo}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="name" className="f2">
                    Name
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Your project name"
                    type="text"
                    className="f2"
                    disabled
                    value={templateData.name}
                    onChange={(e) => setterTemplate("name", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ticker" className="f2">
                    Ticker
                  </Label>
                  <Input
                    id="ticker"
                    name="ticker"
                    placeholder="Your project ticker"
                    type="text"
                    className="f2"
                    disabled
                    value={templateData.ticker}
                    onChange={(e) => setterTemplate("ticker", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ca" className="f2">
                    CA
                  </Label>
                  <Input
                    id="ca"
                    name="ca"
                    placeholder="ca"
                    type="text"
                    className="f2"
                    value={templateData.ca}
                    onChange={(e) => setterTemplate("ca", e.target.value)}
                  />
                </FormGroup>
              </Card>
            </Collapse>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("projectInfo")}
            >
              Project Info
            </div>
            <Collapse isOpen={sidebarCollapse.projectInfo}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="about" className="f2">
                    About
                  </Label>
                  <Input
                    id="about"
                    name="about"
                    placeholder=""
                    type="textarea"
                    className="f2"
                    value={templateData.about}
                    rows={4}
                    onChange={(e) => setterTemplate("about", e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="description" className="f2">
                    Description
                  </Label>
                  <Input
                    id="description"
                    name="description"
                    placeholder=""
                    type="textarea"
                    className="f2"
                    value={templateData.description}
                    rows={4}
                    onChange={(e) =>
                      setterTemplate("description", e.target.value)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="f2">Logo</Label>
                  <Label
                    for="logo"
                    className={`image-upload ${
                      templateData.logo ? "filled" : ""
                    }`}
                  >
                    <img src={templateData.logo} x-if={templateData.logo} />
                  </Label>
                  <input
                    id="logo"
                    type="file"
                    onChange={(e) => handleFileUpload(e, "logo")}
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="f2">Banner</Label>
                  <Label
                    for="banner"
                    className={`image-upload banner ${
                      templateData.banner ? "filled" : ""
                    }`}
                  >
                    <img src={templateData.banner} x-if={templateData.banner} />
                  </Label>
                  <input
                    id="banner"
                    type="file"
                    onChange={(e) => handleFileUpload(e, "banner")}
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="f2">Background Image</Label>
                  <Label
                    for="backgroundImage"
                    className={`image-upload banner ${
                      templateData.backgroundImage ? "filled" : ""
                    }`}
                  >
                    <img
                      src={templateData.backgroundImage}
                      x-if={templateData.backgroundImage}
                    />
                  </Label>
                  <input
                    id="backgroundImage"
                    type="file"
                    onChange={(e) => handleFileUpload(e, "backgroundImage")}
                  />
                </FormGroup>
              </Card>
            </Collapse>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("socials")}
            >
              Socials
            </div>
            <Collapse isOpen={sidebarCollapse.socials}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="telegram" className="f2">
                    Telegram
                  </Label>
                  <Input
                    id="telegram"
                    name="telegram"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.telegram}
                    onChange={(e) => setterSocials("telegram", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="twitter" className="f2">
                    Twitter
                  </Label>
                  <Input
                    id="twitter"
                    name="twitter"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.twitter}
                    onChange={(e) => setterSocials("twitter", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="pumpfun" className="f2">
                    Pump Fun
                  </Label>
                  <Input
                    id="pumpfun"
                    name="pumpfun"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.pumpfun}
                    onChange={(e) => setterSocials("pumpfun", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="jupiter" className="f2">
                    Jupiter
                  </Label>
                  <Input
                    id="jupiter"
                    name="jupiter"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.jupiter}
                    onChange={(e) => setterSocials("jupiter", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="raydium" className="f2">
                    Raydium
                  </Label>
                  <Input
                    id="raydium"
                    name="raydium"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.raydium}
                    onChange={(e) => setterSocials("raydium", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="birdeye" className="f2">
                    Birdeye
                  </Label>
                  <Input
                    id="birdeye"
                    name="birdeye"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.birdeye}
                    onChange={(e) => setterSocials("birdeye", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="dexscreener" className="f2">
                    Dexscreener
                  </Label>
                  <Input
                    id="dexscreener"
                    name="dexscreener"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.dexscreener}
                    onChange={(e) =>
                      setterSocials("dexscreener", e.target.value)
                    }
                  />
                </FormGroup>
              </Card>
            </Collapse>
          </div>

          <div className="sidebar-footer">
            <Button
              type="button"
              color="info"
              onClick={() => setVisible(true)}
              x-if={!connected}
            >
              <img src={require("../../assets/images/phantom-fill.png")} />
              <span className="f2">Connect Wallet</span>
            </Button>
            <Button color="success" x-else-if={connected} type="submit">
              <span className="f2" style={{ color: "black" }}>
                Update
              </span>
            </Button>
          </div>
        </Form>

        <div className="preview">{renderTemplate()}</div>
      </div>
    </div>
  );
}
