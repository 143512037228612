import React, { useEffect, useState } from "react";
import "./home.scss";
import Navbarr from "../../components/Navbar";
import { Button, Container, Input } from "reactstrap";
import API from "../../utils/api";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { generateNonce } from "../../utils/global.js";
import bs58 from "bs58";
import {
  clusterApiUrl,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  useWalletModal,
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import ProfileModal from "../../components/ProfileModal";

const wcus = [
  {
    title: "Ready Made Template",
    icon: require("../../assets/images/layout.png"),
  },
  {
    title: "Customizable Content",
    icon: require("../../assets/images/customize.png"),
  },
  {
    title: "Free Subdomain",
    icon: require("../../assets/images/rocket.png"),
  },
  {
    title: "No-Cost Custom Domain",
    icon: require("../../assets/images/domain.png"),
  },
  {
    title: "Almost Free",
    icon: require("../../assets/images/cent.png"),
  },
];

export default function Home() {
  const { connected, publicKey, signMessage, sendTransaction, disconnect } =
    useWallet();
  const { connection } = useConnection();
  const { setVisible } = useWalletModal();
  const [profileModal, setProfileModal] = useState(false);
  const [solBalance, setSolBalance] = useState(null);

  const [webs, setWebs] = useState([]);
  const [nonce, setNonce] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [signature, setSignature] = useState(null);
  const [input, setInput] = useState("");
  const [available, setAvailable] = useState(null);
  const [inputDebounce] = useDebounce(input, 500);
  const navigate = useNavigate();

  useEffect(() => {
    if (connected) {
      if (!localStorage.getItem("jwt")) {
        loginSign();
      }
      getSolBalance();
    }
  }, [connected]);
  useEffect(() => {
    getWebs();
  }, []);

  useEffect(() => {
    checkSubdomain();
  }, [inputDebounce]);

  const loginSign = () => {
    const nonce = generateNonce(12);
    setNonce(nonce);
    const timestamp = Date.now();
    setTimestamp(timestamp);
    const messageText = `Sign in with Solana to webm.fun app.\nNonce: ${nonce}\nTimestamp: ${timestamp}`;
    const messageEncoded = new TextEncoder().encode(messageText);
    signMessage(messageEncoded)
      .then((resp) => {
        setSignature(bs58.encode(resp));
        login();
      })
      .catch(() => {});
  };

  const login = () => {
    if (!signature) return;
    API.post("login", {
      nonce: nonce,
      timestamp: timestamp,
      signature: signature,
      address: publicKey ? publicKey.toString() : "",
    }).then((res) => {
      console.log(res.data);

      localStorage.setItem("jwt", res.data.token);
    });
  };
  const getSolBalance = async () => {
    if (!publicKey) return;
    const balanceRaw = await connection.getBalance(publicKey);
    const balance = parseFloat(balanceRaw / LAMPORTS_PER_SOL).toFixed(3);
    setSolBalance(balance);
  };
  const checkSubdomain = async () => {
    if (!inputDebounce) {
      setAvailable(null);
      return;
    }
    const response = await API.get(`check/${inputDebounce}`);
    setAvailable(response.data.available);
  };
  const getWebs = async () => {
    const response = await API.get("web/find");
    setWebs(response.data.data);
  };

  const onCreateWebsite = () => {
    if (!input) {
      toast.error("Fill subdomain first");
      return;
    }

    navigate(`/create/${input}`);
  };
  return (
    <div className="home">
      <ProfileModal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        publicKey={publicKey}
        balance={solBalance}
        onDisconnect={() => {
          disconnect();
          localStorage.removeItem("jwt");
          setProfileModal(false);
        }}
      />
      <Navbarr
        onConnect={() => setVisible(true)}
        connected={connected}
        publicKey={publicKey}
        onOpenProfile={() => setProfileModal(true)}
      />
      <div className="hero">
        <h1 className="f1">
          Create Meme Website
          <br /> in a Snap
        </h1>

        <div className="hero-input">
          <div className="input-container">
            <div className="input-wrapper">
              <Input
                type="text"
                className="f2"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <span className="append f2">.webm.fun</span>
            </div>

            <Button color="success" className="f2" onClick={onCreateWebsite}>
              <span className="f2">Create Website</span>
            </Button>
          </div>

          <span
            x-if={available == false}
            className="text-danger mt-1 f2"
            style={{ fontSize: "0.8em" }}
          >
            {input}.webm.fun is not available
          </span>

          <span
            x-if={available == true}
            className="text-success mt-1 f2"
            style={{ fontSize: "0.8em" }}
          >
            {input}.webm.fun is available
          </span>
          <div className="powered">
            <div>
              <span className="f2">Powered by </span>
              <img src={require("../../assets/images/bump.png")} />
              <span className="f2">BumpIsFun</span>
            </div>
          </div>
        </div>

        <div className="featured-container">
          <div className="featured-list">
            <div
              className="featured-item"
              x-for={(item, i) in wcus}
              key={`wcu-${i}`}
            >
              <img src={item.icon} />
              <h5 className="f2">{item.title}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="webs">
        <Container>
          <h2 className="f1 mb-5">New Web</h2>
          <div className="web-list">
            <div className="web-item" x-for={(web, i) in webs} key={`web-${i}`}>
              <div className="web-image">
                <img src={`${process.env.REACT_APP_BASE_URL}/${web.logo}`} />
              </div>

              <div className="web-content">
                <h5 className="f2">{web.name}</h5>
                <h4 className="f1">{web.ticker}</h4>
                <div className="d-flex flex-row">
                  <div
                    className={`website f2 ${
                      web.subdomain.length > 11 ? "smaller" : ""
                    }`}
                    onClick={() =>
                      window.open(
                        web.domain
                          ? `https://${web.domain}`
                          : `https://${web.subdomain}.webm.fun`,
                        "_blank"
                      )
                    }
                  >
                    {web.domain ? web.domain : `${web.subdomain}.webm.fun`}
                  </div>
                </div>
                <div className="buttons">
                  <button
                    x-if={web.socials.telegram}
                    onClick={() => window.open(web.socials.telegram, "_blank")}
                  >
                    <img src={require("../../assets/images/tg-fill.png")} />
                  </button>
                  <button
                    x-if={web.socials.twitter}
                    onClick={() => window.open(web.socials.twitter, "_blank")}
                  >
                    <img src={require("../../assets/images/x-fill.png")} />
                  </button>
                  <button
                    x-if={
                      web.socials.pumpfun &&
                      !web.socials.jupiter &&
                      !web.socials.raydium
                    }
                    onClick={() => window.open(web.socials.pumpfun, "_blank")}
                    className="bg-white"
                  >
                    <img src={require("../../assets/images/pump-fill.png")} />
                  </button>
                  <button
                    x-if={web.socials.jupiter}
                    onClick={() => window.open(web.socials.jupiter, "_blank")}
                    className="bg-white"
                  >
                    <img src={require("../../assets/images/jup-fill.png")} />
                  </button>
                  <button
                    x-if={web.socials.raydium}
                    onClick={() => window.open(web.socials.raydium, "_blank")}
                  >
                    <img src={require("../../assets/images/ray-fill.png")} />
                  </button>
                  <button
                    x-if={web.socials.dexscreener}
                    onClick={() =>
                      window.open(web.socials.dexscreener, "_blank")
                    }
                  >
                    <img src={require("../../assets/images/ds-fill.png")} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
