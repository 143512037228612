import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../components/Navbar";

import {
  clusterApiUrl,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  useWalletModal,
  WalletModalProvider,
} from "@solana/wallet-adapter-react-ui";
import "./create.scss";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  Label,
  InputGroupText,
  Form,
} from "reactstrap";
import ProfileModal from "../../components/ProfileModal.jsx";
import bs58 from "bs58";
import { generateNonce } from "../../utils/global.js";
import API from "../../utils/api.js";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";

require("@solana/wallet-adapter-react-ui/styles.css");

const themes = [
  {
    name: "Classic",
    code: "classicsimple",
  },
  {
    name: "Classic Advance",
    code: "classicadvance",
  },
];
export default function Create() {
  const { connected, publicKey, signMessage, sendTransaction, disconnect } =
    useWallet();
  const { connection } = useConnection();
  const { setVisible } = useWalletModal();
  const [nonce, setNonce] = useState(null);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [component, setComponent] = useState(null);
  const [templateFiles, setTemplateFiles] = useState({
    backgroundImage: null,
    banner: null,
    logo: null,
  });
  const { name } = useParams();

  const [deployTx, setDeployTx] = useState(null);
  const [deployStatus, setDeployStatus] = useState(null);
  const [webmTicket, setWebmTicket] = useState(null);
  const [deployLoading, setDeployLoading] = useState(false);
  const [deployCoupon, setDeployCoupon] = useState("");
  const [deployCouponValid, setDeployCouponValid] = useState(null);
  const [subdomainStatus, setSubdomainStatus] = useState(null);
  const [templateData, setTemplateData] = useState({
    theme: "classicsimple",
    subdomain: name,
    name: "Your Project Name",
    ticker: "TICKER",
    color: "#FFFFFF",
    background: "#000000",
    ca: "",
    logo: "",
    description: "Fill your project description",
    about: "",
    backgroundImage: "",

    banner: "",

    socials: {
      dexscreener: null,
      raydium: null,
      jupiter: null,
      pumpfun: null,
      telegram: null,
      twitter: null,
      birdeye: null,
    },
  });
  const [subdomainDebounce] = useDebounce(templateData.subdomain, 1000);

  const [sidebarCollapse, setSidebarCollapse] = useState({
    theme: true,
    tokenInfo: false,
    projectInfo: false,
    socials: false,
    style: false,
  });
  const [profileModal, setProfileModal] = useState(false);
  const [solBalance, setSolBalance] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getLayout();
  }, [templateData.theme]);

  useEffect(() => {
    if (connected) {
      if (!localStorage.getItem("jwt")) {
        loginSign();
      }

      getWebmTicket();
      getSolBalance();
    }
  }, [connected]);

  useEffect(() => {
    if (publicKey) {
      console.log(publicKey.toString());
    }
  }, [publicKey]);

  useEffect(() => {
    login();
  }, [signature]);

  useEffect(() => {
    if (deployStatus == "waiting") {
      toast.dismiss();
      toast.loading("Confirm transaction in Phantom App");
    } else if (deployStatus == "confirming") {
      toast.dismiss();
      toast.loading("Transaction submitted, waiting for block confirmation");
    } else if (deployStatus == "confirmed") {
      toast.dismiss();
      toast.loading("Transaction confirmed, deploy a website....");
      deployWebsite(true);
    } else if (deployStatus == "deploying") {
      toast.dismiss();
      toast.loading("Deploying a website....");
      // deployWebsite();
    } else if (deployStatus == "success") {
      toast.dismiss();
      toast.success("Website successfully deployed");
    } else if (deployStatus == "cancel") {
      toast.dismiss();
      toast.error("Transaction cancel");
    } else if (deployStatus == "error") {
      toast.dismiss();
      toast.error("Transaction error");
    }
  }, [deployStatus]);

  useEffect(() => {
    if (subdomainDebounce) {
      API.post(`subdomain/${subdomainDebounce}`)
        .then((res) => {
          console.log("res", res.data.status);

          setSubdomainStatus(res.data.status);
        })
        .catch(() => {
          setSubdomainStatus(false);
        });
    }
  }, [subdomainDebounce]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const getLayout = async () => {
    const templateFiles = {
      classicsimple: "ClassicSimple",
      classicadvance: "ClassicAdvance",
    };
    const importedComponent = await import(
      `../../Template/${templateData.theme}/${
        templateFiles[templateData.theme]
      }.jsx`
    );
    setComponent(importedComponent);
  };

  const toggleSidebarCollapse = (name) => {
    setSidebarCollapse((p) => ({
      ...p,
      [name]: !p[name],
    }));
  };

  const setterTemplate = (key, value) => {
    setTemplateData((p) => ({
      ...p,
      [key]: value,
    }));
  };
  const setterSocials = (key, value) => {
    setTemplateData((p) => ({
      ...p,
      socials: {
        ...p.socials,
        [key]: value,
      },
    }));
  };

  const loginSign = () => {
    const nonce = generateNonce(12);
    setNonce(nonce);
    const timestamp = Date.now();
    setTimestamp(timestamp);
    const messageText = `Sign in with Solana to webm.fun app.\nNonce: ${nonce}\nTimestamp: ${timestamp}`;
    const messageEncoded = new TextEncoder().encode(messageText);
    signMessage(messageEncoded)
      .then((resp) => {
        setSignature(bs58.encode(resp));
        login();
      })
      .catch(() => {});
  };

  const login = () => {
    if (!signature) return;
    API.post("login", {
      nonce: nonce,
      timestamp: timestamp,
      signature: signature,
      address: publicKey ? publicKey.toString() : "",
    }).then((res) => {
      localStorage.setItem("jwt", res.data.token);
      getWebmTicket();
    });
  };

  const getWebmTicket = async () => {
    const response = await API.get("user/webmticket", {
      address: publicKey.toString(),
    }).catch(() => false);

    if (!response) return;

    let totalTicket = 0;
    let premiumUser = false;
    try {
      totalTicket = response.data.data;
      premiumUser = response.data.isPremium;
    } catch {}
    setWebmTicket(premiumUser ? true : totalTicket);
  };

  const renderTemplate = () => {
    if (!component) return;
    const Component = component.default;
    return <Component data={templateData} />;
  };

  const handleFileUpload = (e, name) => {
    setTemplateFiles((p) => ({
      ...p,
      [name]: e.target.files[0],
    }));

    setterTemplate(name, URL.createObjectURL(e.target.files[0]));
  };

  const getSolBalance = async () => {
    if (!publicKey) return;
    const balanceRaw = await connection.getBalance(publicKey);
    const balance = parseFloat(balanceRaw / LAMPORTS_PER_SOL).toFixed(3);
    setSolBalance(balance);
  };

  const deploy = async () => {
    if (!templateData.name || !templateData.ticker || !templateFiles.logo) {
      toast.error("Name, ticker and logo cannot be blank");
      return;
    }
    try {
      setDeployLoading(true);
      setDeployStatus("waiting");
      const transaction = new Transaction();
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(process.env.REACT_APP_FEE_ADDRESS),
          lamports:
            parseFloat(process.env.REACT_APP_FEE_AMOUNT) * LAMPORTS_PER_SOL,
        })
      );

      const hash = await sendTransaction(transaction, connection).catch(() => {
        return false;
      });

      if (!hash) {
        setDeployStatus("cancel");
        return;
      }
      setDeployTx(hash);

      setDeployStatus("confirming");
      for (let i = 0; i < 60; i++) {
        await delay(1000);

        const _status = await connection
          .getSignatureStatus(hash, {
            searchTransactionHistory: true,
          })
          .then((result) => {
            return result;
          })
          .catch((err) => {
            return false;
            // console.log("err", err);
          });
        if (!_status) continue;

        if (_status.value?.confirmationStatus === "finalized") {
          setDeployStatus("confirmed");
          break;
        }
      }
    } catch {
      setDeployLoading(false);
      setDeployTx("error");
    }
  };
  useEffect(() => {}, [window.screen.width]);

  const deployWebsite = async (bypassLoading = false) => {
    //upload image

    if (!templateData.name || !templateData.ticker || !templateFiles.logo) {
      toast.error("Name, ticker and logo cannot be blank");
      return;
    }
    if (deployLoading && !bypassLoading) return;
    setDeployLoading(true);
    setDeployStatus("deploying");
    let backgroundImageUrl = "";
    if (templateFiles.backgroundImage) {
      const uploading = await API.upload(templateFiles.backgroundImage);
      if (uploading.result) {
        backgroundImageUrl = uploading.result;
      }
    }

    let logoUrl = "";
    if (templateFiles.logo) {
      const uploading = await API.upload(templateFiles.logo);
      if (uploading.result) {
        logoUrl = uploading.result;
      }
    }
    let bannerUrl = "";
    if (templateFiles.banner) {
      const uploading = await API.upload(templateFiles.banner);
      if (uploading.result) {
        bannerUrl = uploading.result;
      }
    }

    const payload = {
      ...templateData,
      hash: deployTx,
      template: templateData.theme,
    };
    if (backgroundImageUrl) payload.backgroundImage = backgroundImageUrl;
    if (logoUrl) payload.logo = logoUrl;
    if (bannerUrl) payload.banner = bannerUrl;

    const deploying = await API.post("web/deploy", payload).catch(() => false);
    if (!deploying) {
      setDeployStatus("error");

      setDeployLoading(false);
      return;
    }
    setDeployStatus("success");

    setDeployLoading(false);
    setTimeout(() => {
      navigate(`/edit/${deploying.data.data.subdomain}`);
    }, 2000);
  };
  return (
    <div className="create-container">
      <Navbar
        onConnect={() => setVisible(true)}
        connected={connected}
        publicKey={publicKey}
        onOpenProfile={() => setProfileModal(true)}
      />

      <ProfileModal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        publicKey={publicKey}
        balance={solBalance}
        onDisconnect={() => {
          disconnect();
          localStorage.removeItem("jwt");
          setProfileModal(false);
        }}
      />

      <div className="layout-container">
        <Form
          className={`sidebar ${!sidebarOpen ? "hide" : ""}`}
          onSubmit={(e) => {
            e.preventDefault();
            if (webmTicket && webmTicket > 0) {
              deployWebsite();
            } else {
              deploy();
            }
          }}
        >
          <button
            className="toggle-button"
            type="button"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <span> {sidebarOpen ? "👉" : "👈"}</span>
          </button>
          <div className="sidebar-inner">
            <InputGroup className="f2">
              <Input
                className="f2"
                value={templateData.subdomain}
                placeholder="subdomain"
                onChange={(e) => setterTemplate("subdomain", e.target.value)}
              />
              <InputGroupText className="f2">.webm.fun</InputGroupText>
            </InputGroup>
            <span
              x-if={subdomainStatus == false}
              className="text-danger"
              style={{ fontSize: "0.8em" }}
            >
              {subdomainDebounce}.webm.fun is not available
            </span>
            <span
              x-if={subdomainStatus == true}
              className="text-success"
              style={{ fontSize: "0.8em" }}
            >
              {subdomainDebounce}.webm.fun is available
            </span>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("theme")}
            >
              Meme Theme
            </div>
            <Collapse isOpen={sidebarCollapse.theme}>
              <Card className="collapse-card">
                <Button
                  type="button"
                  className="w-100 mb-1"
                  color={theme.code === templateData.theme ? "primary" : "dark"}
                  outline={theme.code !== templateData.theme}
                  x-for={(theme, i) in themes}
                  onClick={() => setterTemplate("theme", theme.code)}
                >
                  {theme.name}
                </Button>
              </Card>
            </Collapse>

            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("style")}
            >
              Style
            </div>
            <Collapse isOpen={sidebarCollapse.style}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="color" className="f2">
                    Color
                  </Label>
                  <Input
                    id="color"
                    name="color"
                    placeholder=""
                    type="color"
                    className="f2"
                    value={templateData.color}
                    onChange={(e) => setterTemplate("color", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="background" className="f2">
                    Background Color
                  </Label>
                  <Input
                    id="background"
                    name="background"
                    placeholder=""
                    type="color"
                    className="f2"
                    value={templateData.background}
                    onChange={(e) =>
                      setterTemplate("background", e.target.value)
                    }
                  />
                </FormGroup>
              </Card>
            </Collapse>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("tokenInfo")}
            >
              Token Info
            </div>
            <Collapse isOpen={sidebarCollapse.tokenInfo}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="name" className="f2">
                    Name
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Your project name"
                    type="text"
                    className="f2"
                    value={templateData.name}
                    onChange={(e) => setterTemplate("name", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ticker" className="f2">
                    Ticker
                  </Label>
                  <Input
                    id="ticker"
                    name="ticker"
                    placeholder="Your project ticker"
                    type="text"
                    className="f2"
                    value={templateData.ticker}
                    onChange={(e) => setterTemplate("ticker", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ca" className="f2">
                    CA
                  </Label>
                  <Input
                    id="ca"
                    name="ca"
                    placeholder="ca"
                    type="text"
                    className="f2"
                    value={templateData.ca}
                    onChange={(e) => setterTemplate("ca", e.target.value)}
                  />
                </FormGroup>
              </Card>
            </Collapse>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("projectInfo")}
            >
              Project Info
            </div>
            <Collapse isOpen={sidebarCollapse.projectInfo}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="about" className="f2">
                    About
                  </Label>
                  <Input
                    id="about"
                    name="about"
                    placeholder=""
                    type="textarea"
                    className="f2"
                    value={templateData.about}
                    rows={4}
                    onChange={(e) => setterTemplate("about", e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="description" className="f2">
                    Description
                  </Label>
                  <Input
                    id="description"
                    name="description"
                    placeholder=""
                    type="textarea"
                    className="f2"
                    value={templateData.description}
                    rows={4}
                    onChange={(e) =>
                      setterTemplate("description", e.target.value)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="f2">Logo</Label>
                  <Label
                    for="logo"
                    className={`image-upload ${
                      templateData.logo ? "filled" : ""
                    }`}
                  >
                    <img src={templateData.logo} x-if={templateData.logo} />
                  </Label>
                  <input
                    id="logo"
                    type="file"
                    onChange={(e) => handleFileUpload(e, "logo")}
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="f2">Banner</Label>
                  <Label
                    for="banner"
                    className={`image-upload banner ${
                      templateData.banner ? "filled" : ""
                    }`}
                  >
                    <img src={templateData.banner} x-if={templateData.banner} />
                  </Label>
                  <input
                    id="banner"
                    type="file"
                    onChange={(e) => handleFileUpload(e, "banner")}
                  />
                </FormGroup>

                <FormGroup>
                  <Label className="f2">Background Image</Label>
                  <Label
                    for="backgroundImage"
                    className={`image-upload banner ${
                      templateData.backgroundImage ? "filled" : ""
                    }`}
                  >
                    <img
                      src={templateData.backgroundImage}
                      x-if={templateData.backgroundImage}
                    />
                  </Label>
                  <input
                    id="backgroundImage"
                    type="file"
                    onChange={(e) => handleFileUpload(e, "backgroundImage")}
                  />
                </FormGroup>
              </Card>
            </Collapse>
            <div
              className="collapse-button f2"
              onClick={() => toggleSidebarCollapse("socials")}
            >
              Socials
            </div>
            <Collapse isOpen={sidebarCollapse.socials}>
              <Card className="collapse-card">
                <FormGroup>
                  <Label for="telegram" className="f2">
                    Telegram
                  </Label>
                  <Input
                    id="telegram"
                    name="telegram"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.telegram}
                    onChange={(e) => setterSocials("telegram", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="twitter" className="f2">
                    Twitter
                  </Label>
                  <Input
                    id="twitter"
                    name="twitter"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.twitter}
                    onChange={(e) => setterSocials("twitter", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="pumpfun" className="f2">
                    Pump Fun
                  </Label>
                  <Input
                    id="pumpfun"
                    name="pumpfun"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.pumpfun}
                    onChange={(e) => setterSocials("pumpfun", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="jupiter" className="f2">
                    Jupiter
                  </Label>
                  <Input
                    id="jupiter"
                    name="jupiter"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.jupiter}
                    onChange={(e) => setterSocials("jupiter", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="raydium" className="f2">
                    Raydium
                  </Label>
                  <Input
                    id="raydium"
                    name="raydium"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.raydium}
                    onChange={(e) => setterSocials("raydium", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="birdeye" className="f2">
                    Birdeye
                  </Label>
                  <Input
                    id="birdeye"
                    name="birdeye"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.birdeye}
                    onChange={(e) => setterSocials("birdeye", e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="dexscreener" className="f2">
                    Dexscreener
                  </Label>
                  <Input
                    id="dexscreener"
                    name="dexscreener"
                    placeholder=""
                    type="text"
                    className="f2"
                    value={templateData.socials.dexscreener}
                    onChange={(e) =>
                      setterSocials("dexscreener", e.target.value)
                    }
                  />
                </FormGroup>
              </Card>
            </Collapse>
          </div>

          <div className="sidebar-footer">
            <Button
              type="button"
              color="info"
              onClick={() => setVisible(true)}
              x-if={!connected}
            >
              <img src={require("../../assets/images/phantom-fill.png")} />
              <span className="f2">Connect Wallet</span>
            </Button>
            <div x-else-if={connected}>
              <span
                className="f2 mb-2 d-block text-center"
                style={{ fontSize: "0.8em" }}
                x-if={webmTicket <= 0}
              >
                Deployment Cost : 0.04 SOL
              </span>

              <span
                className="f2 mb-2 d-block text-center"
                style={{ fontSize: "0.8em" }}
                x-if={webmTicket > 0 || webmTicket == true}
              >
                <span x-if={webmTicket === true}>
                  You are <span className="f1 ">PREMIUM USER</span>
                </span>
                <span x-else-if={webmTicket > 0}>
                  You have <span className="f1">{webmTicket} WEBM Ticket</span>
                </span>
                <br />
                Deployment Cost : <s>0.04 SOL</s> <strong>FREE</strong>
              </span>
              <Button color="success" type="submit">
                <span className="f2" style={{ color: "black" }}>
                  🚀 DEPLOY
                </span>
              </Button>
            </div>
          </div>
        </Form>

        <div className="preview">{renderTemplate()}</div>
      </div>
    </div>
  );
}
